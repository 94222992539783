<template>
  <accountDetail :isAdmin="isAdministrator" :account="accountPerson" />
</template>

<script>
import validationfile from "../../validation";
import request from "../../request";
import Countrys from "../../locale/Countrys";
export default {
  data() {
    return {
      rules: validationfile,
      accountPerson: null,
      isAdministrator: null,
    };
  },
  created() {
    request.get("/api/kdesign/account", null, (res) => {
      if (res.success) {
        let account = res;
        account.Country = this.setCountryForLang(res.Country, res.Language);
        res.Addresses.forEach((element, index) => {
          account.Addresses[index].Country = this.setCountryForLang(
            element.Country,
            res.Language
          );
        });
        request.get("/user/" + res.UserId, null, (response) => {
          if (response.success) {
            if (
              Object.prototype.hasOwnProperty.call(
                response.data,
                "Administrator"
              )
            ) {
              this.isAdministrator = response.data.Administrator;
            }
          }
        });
        this.accountPerson = account;
      }
    });
  },
  methods: {
    setCountryForLang(countryCode, lang) {
      const coutnryObj = Countrys.allCountrys.filter(
        (country) => country.Code == countryCode
      );
      return coutnryObj[0][lang];
    },
  },
};
</script>

<style>
</style>